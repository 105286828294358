import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Smartpay = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`Smart Pay | ${title}`}</title>
        <meta
          name="description"
          content="QT-netサービスの便利な決済機能の一つです。駐車場のご利用料金をキャッシュレスで精算することが可能です。スマートフォンを利用することで精算機に触れることなく、便利に/衛生的に、かつポイントを貯めながらお得に利用が可能。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>Smart Pay</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>Smart Pay</li>
        </div>
      </div>

      <div className="main-wrapper smartpay">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>Smart Pay</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <p>
              QT-netサービスの便利な決済機能の一つです。
              <br />
              駐車場のご利用料金をキャッシュレスで精算することが可能です。
              <br />
              スマートフォンを利用することで精算機に触れることなく、便利に/衛生的に、かつポイントを貯めながらお得に利用が可能。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-smartpay-01.webp"
              />
              <img
                className="smartpay-01"
                src="/assets/images/common/service-smartpay-01.png"
                height="285"
                alt=""
              />
            </picture>

            <p className="install">
              ご利用にはスマートフォンアプリ「QT-net駐車場ツール」のインストールが必要になります(
              <a
                href="https://itunes.apple.com/jp/app/zhu-che-changtsuru-for-iphone/id563447907?mt=8&ign-mpt=uo%3D4"
                target="_blank"
                rel="noopener noreferrer"
              >
                iPhoneの方はこちら
              </a>
              、
              <a
                href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool"
                target="_blank"
                rel="noopener noreferrer"
              >
                Androidの方はこちら
              </a>
              )
            </p>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  “SmartPay”マークのある駐車場では、スマホで簡単に決済が可能
                  (クレジットカードの登録が必要となります)
                </li>
                <li>コインパ予約を利用した際は、自動精算が可能</li>
                <li>もれなくポイントも獲得</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>「QT-net 駐車場ツール」の利用方法</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <div className="separate-area">
              <div className="box">
                <h5>iPhoneの方</h5>
                <p>
                  「AppStore」にて「キャッシュレス
                  QT」で検索すると「駐車場ツール」のダウンロード画面が表示されますので、こちらからアプリのインストールを行います。
                </p>

                <a
                  href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                  target="_blank"
                  rel="noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/service-smartpay-02.webp"
                    />
                    <img
                      className="smartpay-02"
                      src="/assets/images/common/service-smartpay-02.png"
                      height="120"
                      alt=""
                    />
                  </picture>
                </a>
              </div>

              <div className="box">
                <h5>Androidの方</h5>
                <p>
                  Google「Playストア」にて「キャッシュレスQT」で検索すると「駐車場ツール」のダウンロード画面が表示されます。こちらからアプリのインストールを行います。
                </p>
                <a
                  href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                  target="_blank"
                  rel="noreferrer"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="/assets/images/common/service-smartpay-03.webp"
                    />
                    <img
                      className="smartpay-03"
                      src="/assets/images/common/service-smartpay-03.png"
                      height="120"
                      alt=""
                    />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>現金いらず、スマホでのキャッシュレス精算方法</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-02">
            <p>
              スマホアプリから車室番号を指定することで、精算機に行かずに車内でもどこでも簡単に精算が可能
            </p>

            <ul className="small-points">
              <li>登録されたクレジットカードから精算します</li>
              <li>予約と支払いもスマートフォンで利用可能</li>
            </ul>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/pc/service-smartpay-04.webp"
              />
              <img
                className="pc-only smartpay-04"
                src="/assets/images/pc/service-smartpay-04.png"
                alt=""
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/sp/service-smartpay-04.webp"
              />
              <img
                className="sp-only smartpay-04"
                src="/assets/images/sp/service-smartpay-04.png"
                height="1376"
                alt=""
              />
            </picture>

            <div className="separate-area">
              <div className="box divided-3">
                <p>”SmartPayで料金精算”をタップ</p>
              </div>
              <div className="box divided-3">
                <p>駐車場一覧から停めた駐車場を選択</p>
              </div>
              <div className="box divided-3">
                <p>
                  車室番号を指定することで料金が表示
                  。”精算する”ボタンで簡単に精算終了
                </p>
              </div>
            </div>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Smartpay
